<template>
  <div style="text-align: center">
    <p
      class="has-text-weight-semibold is-italic has-text-danger"
      style="padding-left: 2em"
    >
      {{ message }}
    </p>
    <img
      style="padding-left: 2em; max-height: 8em; padding-top: 1em"
      src="@/assets/img/santa.webp"
    />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'CustomDisplayMessage',
  computed: {
    message() {
      return `Merry Christmas to everyone at ${this.$configData.customer.displayName} from everyone at Leadent, wishing you all the very best
      for 2020.`;
    },
  },
});
</script>

<style></style>
